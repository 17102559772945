form {
    .form__field {
        margin-bottom: 30px;
        label {
            display: block;
            margin-bottom: 5px;
            &.freeform-required {
                &::after {
                    content: "*";
                    color: $color-error;
                    margin-left: 5px;
                    display: inline;
                }
            }
        }

        input[type='text'],
        input[type='password'],
        input[type='email'],
        input[type='tel'],
        textarea,
        select {
            height: 45px;
            background-color: transparent;
            width: 100%;
            padding: 0 15px;
            border-width: 0 0 2px;
            border-style: solid;
            border-color: #a4a4b2;
            border-radius: 0;
            &.ff-has-errors {
                border-color: $color-error;
            }
        }
        .ff-errors {
            color: $color-error;
            padding-top: 5px;
            li {
                list-style-type: none;
            }
        }

        input[type='checkbox'],
        input[type='radio'] {
            display: block;
            opacity: 1;
            position: absolute;
            top: 12px;
            left: 12px;
            transform: translate(-50%, -50%);
            width: auto;
            height: auto;
        }

        textarea {
            height: 45px;
            &:focus {
                padding: 15px;
                min-height: 135px;
            }
        }

        select {
            position: relative;
            appearance: none;
            outline: 0;
            cursor: pointer;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 284.929 284.929'%3E %3Cpath fill='white' d='M282.082 76.51L267.808 62.24c-1.902-1.906-4.093-2.856-6.57-2.856-2.47 0-4.66.95-6.563 2.856L142.465 174.44 30.263 62.24c-1.903-1.905-4.093-2.855-6.567-2.855-2.475 0-4.665.95-6.567 2.856L2.856 76.516C.95 78.417 0 80.607 0 83.082c0 2.473.953 4.663 2.856 6.565L135.9 222.693c1.9 1.903 4.092 2.854 6.566 2.854s4.66-.95 6.562-2.854L282.082 89.647c1.902-1.903 2.847-4.093 2.847-6.565 0-2.475-.946-4.665-2.848-6.57z'/%3E %3C/svg%3E");
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 20px;

            &::-ms-expand {
                display: none;
            }

            &:focus::-ms-value {
                color: white;
                background-color: transparent;
            }

            [class^='field--inline'] & {
                flex: 1 1 auto;
            }
        }

        .freeform-input-only-label {
            position: relative;
            padding-left: 30px;
            line-height: 1.7;
        }

        .freeform-input-radio-label {
            + label {
                position: relative;
                padding-left: 40px;
                + label {
                    position: relative;
                    padding-left: 40px;
                }
            }
        }
        .freeform-label {
            color: $color-font-label;
        }
    }
}

.ff-form-errors {
    p {
        color: $color-error;
        padding: 5px 0;
    }
}

.success,
.success-signup,
.failure-signup {
    border-radius: $root-radius;
    width: 350px;
    height: 130px;
    padding: 20px;
    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        color: $black;
        i {
            font-size: 70px;
            padding-right: 20px;
            color: $white;
            padding-top: 15px;
        }
    }
}

.success {
    background-color: #45d67f;
}

.failure-signup,
.success-signup {
    position: fixed;
    bottom: 25px;
    right: 50%;
    margin-right: -175px;
    display: none;
    > div {
        i {
            line-height: 1;
            padding-top: 10px;
        }
    }
}

.failure-signup {
    background-color: red;
}

.success-signup {
    background-color: #45d67f;
}
