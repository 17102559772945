/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
    &:focus {
        .flickity-enabled:focus {
            outline: none;
        }
    }
    &.is-draggable {
        -webkit-tap-highlight-color: transparent;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &.flickity-viewport {
            cursor: move;
            cursor: -webkit-grab;
            cursor: grab;
            &.is-pointer-down {
                cursor: -webkit-grabbing;
                cursor: grabbing;
            }
        }
    }
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
    .flickity-slider {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, .75);
    border: none;
    color: #333;
    &:hover {
        background: white;
        cursor: pointer;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 5px #19f;
    }

    &:active {
        opacity: .6;
    }

    &:disabled {
        opacity: .3;
        cursor: auto;
        pointer-events: none;
    }
    &-icon {
        fill: currentColor;
    }
}

.flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    transform: translateY(-50%);
    &.previous {
        left: 10px;
    }

    &.next {
        right: 10px;
    }
}

.flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
    .dot {
        display: inline-block;
        width: 20px;
        height: 3px;
        margin: 0 8px;
        background: $color-dots-inactive;
        border-radius: 25%;
        opacity: 1;
        cursor: pointer;
        &.is-selected {
            background: $color-dots-active;
        }
    }
}

.flickity-rtl .flickity-page-dots {
    direction: rtl;
}

.flickity-prev-next-button {
    display: none;
    @include mq(xs) {
        display: inline-block;
        width: $arrow-width;
        height: $arrow-height;
        border-radius: $arrow-border-radius;
        background-color: $arrow-bg-slider;
        color: $arrow-color-slider;
        box-shadow: 0 0 2px grey;
    }
    &:focus {
        box-shadow: none;
    }
    &:hover {
        background-color: $arrow-bg-slider-active;
        color: $arrow-color-slider-active;
    }
    &.previous {
        left: -20px;
    }
    &.next {
        right: -20px;
    }
    &:disabled {
        display: none;
    }
}

.home-slider {
    .flick__item {
        margin-right: 20px;
        width: calc((100% - 20px) / 2);

        @include mq(xs) {
            width: calc((100% - 40px) / 3);
        }
        @include mq(md) {
            width: calc((100% - 60px) / 4);
        }
        .show-card {
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
            height: 100%;
            max-height: inherit;
            img {
                width: 100%;
                border-radius: $root-radius;
            }
            .title {
                padding-top: 10px;
                padding-bottom: 20px;
                color: $color-font-primary;
                font-size: 15px;
                font-weight: bold;
            }
        }
    }
    .flickity-page-dots {
        bottom: -5px;
        @include mq(sm) {
            bottom: -20px;
        }
    }

    &::before {
        display: block;
        content: attr(data-status);
        position: absolute;
        top: 100%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        font-size: 1em;
    }
}

.grille-slider {
    a {
        display: none;
    }
    max-height: 100px;
    .flickity-viewport {
        border: 1px solid $color-schedule-border;
        border-radius: $root-radius;
        height: 100%;
        .flickity-slider {
            height: 70px;
            @include mq(xs) {
                height: 100px;
            }
            @include mq(sm) {
                height: 101px;
            }
        }
        .responsive-card {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid $color-schedule-border;
            &:first-child {
                border-left-width: 0;
            }
            width: calc((100% + 2px) / 2);

            @include mq(xs) {
                width: calc((100% + 2px) / 3);
            }
            @include mq(sm) {
                width: calc((100% + 2px) / 4);
            }
            @include mq(smm, md) {
                width: calc((100% + 2px) / 5);
            }
            @include mq(md) {
                width: calc((100% + 2px) / 7);
            }

            a {
                display: block;
                text-align: center;
                color: $color-schedule-episode;
                font-size: 16px;
                span {
                    size: 18px;
                    display: block;
                    font-weight: bold;
                }
            }
            &.is-initial-select {
                a {
                    color: $color-schedule-show;
                }
                &::after {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: "";
                    display: block;
                    width: calc(100%);
                    height: 5px;
                    background-color: $color-schedule-active;
                }
            }
        }
    }
}

.hero-slider {
    max-width: 1920px;
    margin: 0 auto;
    visibility: hidden;
    &.flickity-enabled {
        visibility: visible;
    }
    .flickity-prev-next-button {
        top: 30%;
        background-color: $arrow-bg-hero;
        color: $arrow-color-hero;
        border: $arrow-border-hero;
        &:hover {
            background-color: $arrow-bg-hero-active;
            color: $arrow-color-hero-active;
        }
        &.previous {
            left: $arrow-distance-hero;
        }
        &.next {
            right: $arrow-distance-hero;
        }
        @include mq(smm) {
            top: 50%;
        }
    }
    .flick__item {
        margin: 0;
        width: 100%;
    }
    .flickity-page-dots {
        @include mq(sm, 1) {
            bottom: 30px;
        }
        .dot {
            opacity: .25;
            &.is-selected {
                opacity: 1;
            }
        }
    }
}
