// ==========================================================================
// Footer
// ==========================================================================

.footer {
    position: relative;
    background-color: $color-background-secondary;
    a {
        color: $color-font-secondary;
    }

    .footer__list {
        list-style-type: none;
        .footer__title {
            font-size: em(16px);
            font-weight: 700;
            color: $color-font-footer-title;
        }
        ul {
            list-style-type: none;
            margin-top: 10px;
            li {
                line-height: 2.1;
            }
            a {
                font-size: em(15px);
                color: $color-font-footer-links;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        form {
            label {
                padding-top: 10px;
                line-height: 1.4;
            }
            .btn {
                padding: 0;
            }
        }
    }

    .logo {
        color: $color-font-secondary;
        font-size: 34px;
        &.me-icon-elle {
            font-size: rem(54px);
        }
    }

    // Footer layout
    // ==========================================================================

    .footer-flex {
        display: flex;
    }

    .row {
        margin-top: -25px;
    }

    .footer-flex__item {
        margin-top: 25px;
    }

    .footer-copyright {
        .container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 1;
            height: 15px;
            div {
                flex: 100%;
                @include mq(sm) {
                    flex: 2;
                    + div {
                        display: block;
                        width: 100%;
                        text-align: right;
                    }
                }
                a {
                    font-size: 13px;
                    position: relative;
                    color: $color-font-footer-copyright;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            p {
                color: $color-font-footer-copyright;
                font-size: em(13px);
                padding-bottom: 15px;
            }
            span {
                display: inline-block;
                padding-left: $grid-gutter-width/6;
                padding-right: $grid-gutter-width/6;
            }
        }
    }
}
