.article {
    div {
        max-width: 850px;
    }
    .header-article {
        display: block;
        @include mq(xs) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        span {
            color: $color-font-h2;
        }
        .share {
            color: $color-font-share;
            align-items: center;
            display: flex;
            .socials {
                margin-left: 40px;
            }
            > div {
                position: relative;
                &::after {
                    position: absolute;
                    right: -20px;
                    top: 5%;
                    bottom: 5%;
                    content: "";
                    display: block;
                    width: 2px;
                    height: 90%;
                    background-color: #a4a4b2;
                }
            }
        }
    }
    img {
        width: 100%;
        padding: 20px 0;
    }
}
