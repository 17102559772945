.typography {
    > *:not(:first-child) {
        margin-top: 20px;
    }
    line-height: 1.5;

    a {
        color: $color-font-footer-copyright;
        &:hover {
            text-decoration: underline;
        }
    }

    p {
        font-size: 16px;
        color: $color-font-primary;
        &:last-child {
            margin-bottom: 0;
        }
    }
    div {
        span {
            font-weight: bold;
        }
    }

    ul {
        list-style-type: none;
        li {
            position: relative;
            padding-left: 20px;
            color: $color-font-primary;
            &::before {
                content: $typo-list-bullet;
                font-family: 'Roboto';
                color: $color-font-primary;
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                font-size: 25px;
                line-height: 1;
            }
        }
    }
    ol {
        li {
            margin-left: 18px;
            color: $color-font-primary;
            padding-left: 10px;
        }
    }
}
