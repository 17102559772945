@mixin mq($breakpoint, $max-bp: 0) {
    $default: map-get($grid-breakpoints, $breakpoint);

    @if ($default) {
        // min width only
        @if ($max-bp == 0) {
            @media (min-width: em($default)) { // stylelint-disable-line
                @content;
            }
        }
        // max width only
        @else if ($max-bp == 1) {
            @media (max-width: em(($default - 1px))) { // stylelint-disable-line
                @content;
            }
        }
        // max width and min width
        @else {
            $max: map-get($grid-breakpoints, $max-bp);
            @if ($max) {
                @media (max-width: em(($max - 1px))) and (min-width: em($default)) { // stylelint-disable-line
                    @content;
                }
            }

            @else {
                @warn 'Unfortunately, no value could be retrieved from `#{$max-bp}`. '
                    + 'Please make sure it is defined in `$grid-breakpoints` map '
                    + 'or use 1 for max-width only';
            }
        }
    }
    @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
            + 'Please make sure it is defined in `$grid-breakpoints` map.';
    }
}

// Clearfix
// --------------------------------------------------------------------------
@mixin clearfix {
    &::after {
        clear: both;
        content: '';
        display: table;
    }
}

/**
 * Renvoie la couleur
 * @param  string $color
 * @return string
*/
@function colors($color) {
    @return map-get($colors, $color);
}

@mixin generateRatios($width, $height, $prefix: "ratio-") {
    $class-name: $prefix + $width + "x" + $height;
    .#{$class-name} {
        padding-bottom: ratio($width, $height);
    }
}

@include generateRatios(16,9);// 16x9
@include generateRatios(4,3); // 4x3


// ==========================================================================
// Banners
// ==========================================================================

@mixin banner($banner-class: '.banner', $banner-height: 100vh, $banner-padding: 0, $banner-breakpoint: sm) {

    #{$banner-class} {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        //z-index: map-get($z-indexes, banner);

        @include mq($banner-breakpoint) {
            min-height: $banner-height;

            &::after {
                content: '';
                display: inline-block;
                min-height: $banner-height;
                visibility: hidden;
                z-index: -1;
            }
        }
    }

    #{$banner-class}__cover {
        @extend %img-cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 0;

        img {
            z-index: 1;
        }

        &::before {
            z-index: 9;
        }
    }

    #{$banner-class}__inner {
        padding: $banner-padding $grid-gutter-width;
        position: relative;
        text-align: center;
        z-index: 10;

        @include mq($banner-breakpoint) {
            width: 100%;
            max-width: $grid-container-width;
        }
    }
}
