section.contact {
    > div {
        max-width: 850px;
        color: $color-font-primary;
    }
    .typography {
        color: $color-font-primary;
        font-size: 16px;
    }
    h4 {
        font-size: 16px;
        font-weight: bold;
        padding: 0;
        text-transform: uppercase;
    }
    .phones {
        span {
            font-weight: bold;
            font-size: 16px;
        }
    }
}
