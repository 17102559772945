.grille {
    .scheduler {
        &__container {
            display: flex;
            position: relative;
            ul {
                list-style-type: none;
                width: 100%;
                border: 1px solid $color-schedule-border;
                border-radius: $root-radius;
                overflow: hidden;
            }

            li {
                padding: 20px 25px;
                border-bottom: 2px solid $color-schedule-border;
                min-height: 140px;
                display: flex;
                flex-direction: column;
                &:first-child {
                    //border-top-left-radius: $root-radius;
                    //border-top-right-radius: $root-radius;
                }
                &:last-child {
                    border-bottom: none;
                    //border-bottom: 2px solid $color-schedule-border;
                    //border-bottom-left-radius: $root-radius;
                    //border-bottom-right-radius: $root-radius;
                }
                .time {
                    color: $color-schedule-time;
                    font-size: 13px;
                    font-weight: bold;
                    span {
                        font-size: 16px;
                        color: $color-schedule-episode;
                        font-weight: normal;
                        display: none;
                    }
                }
                .info {
                    position: relative;
                    .show {
                        color: $color-schedule-show;
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 1.6;
                        a {
                            color: $color-schedule-show;
                            &:hover {
                                color: $color-schedule-episode;
                            }
                        }
                    }
                    .episode {
                        color: $color-schedule-episode;
                        font-size: 14px;
                        line-height: 1.6;
                    }
                    .en-cours {
                        font-weight: bold;
                        padding-top: 10px;
                        font-size: 13px;
                        color: $color-schedule-active;
                        display: none;
                        letter-spacing: $root-letter-spacing;
                    }
                }

                @include mq(xs) {
                    padding: 0;
                    display: flex;
                    flex-direction: row;
                    align-items: stretch;
                    .time {
                        min-width: 120px;
                        text-align: center;
                        font-size: 15px;
                        span {
                            display: block;
                        }
                    }
                    .info {
                        width: 100%;
                        padding: 30px;
                        border-left: 2px solid $color-schedule-border;
                        .show {
                            line-height: 1.2;
                            font-size: 24px;
                        }
                        .episode {
                            font-size: 15px;
                        }
                    }
                }
                @include mq(sm) {
                    .time {
                        min-width: 175px;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }
                    .info {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        .en-cours {
                            position: absolute;
                            right: 30px;
                            top: 37%;
                        }
                    }
                }

                &.is-passed {
                    background-color: $color-schedule-passed-bg;
                    .time,
                    .show,
                    .episode {
                        color: $color-schedule-passed;
                    }
                }

                &.is-active {
                    border-left-width: 10px;
                    @include mq(xs) {
                        border-left-width: 0;
                        .time {
                            background-color: $color-schedule-active-bg;
                            color: $white;
                            span {
                                color: $white;
                            }
                        }
                        .info {
                            background-color: $color-background-light;
                        }
                    }
                    .en-cours {
                        display: list-item;
                        list-style-type: disc;
                        list-style-position: inside;
                        @include mq(sm) {
                            padding: 9px 10px;
                            height: 36px;
                            background: $button-primary;
                            color: $button-primary-text;
                            border-radius: $root-radius;
                            text-align: center;
                            width: 120px;
                        }
                    }
                }
            }
        }
    }

    .title-button {
        display: flex;
        flex-direction: column;
        div {
            display: flex;
            align-items: center;
            flex-basis: 50%;
            + div {
                padding-top: 20px;
            }
        }
        @include mq(xs) {
            flex-direction: row;
            div {
                + div {
                    justify-content: flex-end;
                    padding-top: 0;
                }
            }
        }
    }

    .earlier {
        display: none;
    }
    .container.today {
        text-align: center;
        .earlier {
            display: inline-block;
        }
    }

    .schedule_programs.today {
        .schedule_program.is-passed {
            display: none;
        }
    }
}

