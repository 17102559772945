// ==========================================================================
// Helpers
// ==========================================================================

$spacing-base: (12.5px * 2);
$spacing-xsmall: ($spacing-base * .2);
$spacing-small: ($spacing-base * .65);
$spacing-medium: ($spacing-base * 1);
$spacing-large: ($spacing-base * 2);
$spacing-xlarge: ($spacing-base * 3);

// Spacing
// ==========================================================================

// Extra small
// ======================

.u-margin-top-xs {
    margin-top: $spacing-xsmall;
}

.u-margin-bottom-xs {
    margin-bottom: $spacing-xsmall;
}

.u-padding-top-xs {
    padding-top: $spacing-xsmall;
}

.u-padding-bottom-xs {
    padding-bottom: $spacing-xsmall;
}

.u-padded-xs {
    padding: $spacing-xsmall;
}

// Small
// ======================

.u-margin-top-sm {
    margin-top: $spacing-small;
}

.u-margin-bottom-sm {
    margin-bottom: $spacing-small;
}

.u-padding-top-sm {
    padding-top: $spacing-small;
}

.u-padding-bottom-sm {
    padding-bottom: $spacing-small;
}

.u-padded-sm {
    padding: $spacing-small;
}

// Medium
// ======================

.u-margin-top-md {
    margin-top: $spacing-medium;
}

.u-margin-bottom-md {
    margin-bottom: $spacing-medium;
}

.u-padding-top-md {
    padding-top: $spacing-medium;
}

.u-padding-bottom-md {
    padding-bottom: $spacing-medium;
}

.u-padding-left-md {
    padding-left: $spacing-medium;
}

.u-padding-right-md {
    padding-right: $spacing-medium;
}

.u-padded-md {
    padding: $spacing-medium;
}

// Large
// ======================

.u-margin-top-lg {
    margin-top: $spacing-medium;

    @include mq(sm) {
        margin-top: $spacing-large;
    }
}

.u-margin-bottom-lg {
    margin-bottom: $spacing-medium;

    @include mq(sm) {
        margin-bottom: $spacing-large;
    }
}

.u-padding-top-lg {
    padding-top: $spacing-medium;

    @include mq(sm) {
        padding-top: $spacing-large;
    }
}

.u-padding-bottom-lg {
    padding-bottom: $spacing-medium;

    @include mq(sm) {
        padding-bottom: $spacing-large;
    }
}

.u-padded-lg {
    padding: $spacing-medium;

    @include mq(sm) {
        padding: $spacing-large;
    }
}

// xLarge
// ======================

.u-margin-top-xlg {
    margin-top: $spacing-large;

    @include mq(sm) {
        margin-top: $spacing-large;
    }
}

.u-margin-bottom-xlg {
    margin-bottom: $spacing-large;

    @include mq(sm) {
        margin-bottom: $spacing-xlarge;
    }
}

.u-padding-top-xlg {
    padding-top: $spacing-large;

    @include mq(sm) {
        padding-top: $spacing-xlarge;
    }
}

.u-padding-bottom-xlg {
    padding-bottom: $spacing-large;

    @include mq(sm) {
        padding-bottom: $spacing-xlarge;
    }
}

.u-padded-xlg {
    padding: $spacing-large;

    @include mq(sm) {
        padding: $spacing-xlarge;
    }
}

%img-cover {
    display: block;
    width: 100%;

    & > img,
    picture > img {
        font-family: "cover";
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
    }

    // For IE9, IE10 and IE11
    &.objectfit {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        z-index: 5;

        img {
            opacity: 0;
            display: none;
        }
    }
}

.u-background-color-tertiary {
    background-color: $color-background-tertiary;
}

.u-background-color-secondary {
    background-color: $color-background-secondary;
}

.u-background-color-primary {
    background-color: $color-background-light;
}

