.hero-slider {
    .flickity-page-dots {
        .dot {
            width: 25px;
            border-radius: 0;
            transform: rotate3d(-2, 1, 0, -200deg);
            margin: 0 2px;
        }
    }
    .flickity-prev-next-button.next {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .flickity-prev-next-button.previous {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

form {
    .form__field {
        input[type='text'],
        input[type='password'],
        input[type='email'],
        input[type='tel'],
        textarea,
        select {
            border-color: color(greys, dark);
        }

        input[type='text'],
        input[type='password'],
        input[type='email'],
        input[type='tel'],
        textarea,
        select {
            &:focus {
                transition: background-color .5s ease;
                background-color: color(greys, dark);
                border-color: color(greys, darken);
                border-top-left-radius: $root-radius;
                border-top-right-radius: $root-radius;
            }
        }
    }
}

.pagebonnement {
    .typography {
        img {
            background-color: $white;
            padding: 3px 10px;
        }
    }
}

.banner {
    .banner__inner {
        @include mq(smm, mlg) {
            .banner-card {
                max-width: 495px;
            }
        }
        @include mq(mlg) {
            .banner-card {
                padding: 30px 0;
                max-width: 465px;
            }
        }
    }
}
