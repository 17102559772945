.header {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: $header-height;
    will-change: transform;
    z-index: 100;
    background-color: $color-background-secondary;
    color: $color-font-menu;

    .container {
        height: 100%;
    }

    @include mq(md) {
        height: $header-height-desktop;
    }

    &[header-sticky] {
        position: fixed;
        transition: transform 400ms;
        will-change: transform;
    }

    .header__inner {
        flex: 1;
        display: flex;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        justify-content: space-between;
        align-items: center;

        .header__brand {
            background-size: cover;
            min-width: $brand-width-mobile;
            height: $brand-height-mobile;
            background-image: $logo-img;
            @include mq(md) {
                min-width: $brand-width-desktop;
                height: $brand-height-desktop;
            }
            &::before {
                content: '';
            }
        }

        .socials-signup {
            display: none;
            @include mq(sm) {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: flex-end;
            }
        }
    }
}
