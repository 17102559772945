/**
 * variables
 */

// sizes
$header-height: 60px;
$header-height-desktop: 80px;

//fonts
$font-path:  '../fonts/' !default;

$fonts: (
    googlefont: (
        primary: 'Lato',
    ),
        hosted: (
    )
);

$font-primary: font(googlefont, primary), arial, helvetica, sans-serif;
$font-h1: font(googlefont, primary), arial, helvetica, sans-serif;

$logo-img: url('/dist/img/logos/svg/max.svg');
$hero-background-desktop: url('/dist/img/banners/bg-max-desktop.png');
$hero-background-mobile: url('/dist/img/banners/bg-max-mobile.png');

// colors
$colors: (
    root: (
        black:      #000,
        white:      #fff
    ),
    site: (
        background-secondary: #0f0f10 ,
        background-light: #191a1f,
        background-tertiary: #0f0f10,
        dark-blue: #1d3854,
        blue: #0d447d,
        orange: #f37032,
        menu: #0f0f10,
        error: #d83030,
    ),
    buttons: (
        blue: #0d447d,
        blue-hover: #1d3854,
        blue-active: #122133,
        blue-text: #e7e8e6,
        blue-text-hover: #e7e8e6,
        grey: #333640,
        grey-hover: #0d447d,
        grey-active: #1d3854,
        grey-text: #e7e8e6,
        grey-text-hover: #e7e8e6,
    ),
    greys: (
        xdark:          #333640,
        dark:           #373942,
        darken:         #717174,
        base:           #8a8a8d,
        light:          #a3a3a5,
        xlight:         #e7e8e6,
    ),
) !default;

// fonts
//
// font, line-height, and color for body text, headings, and more.
$root-font-size: 15px;

$font-size-base:      1rem !default;// assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * .875) !default;

$font-weight-light:   300 !default;
$font-weight-normal:  400 !default;
$font-weight-bold:    700 !default;

$font-weight-base:    $font-weight-normal !default;
$line-height-base:    1.5 !default;

$root-radius: 7px;

$root-letter-spacing: 1px;

// layout variables
$brand-width-mobile: 36px;
$brand-height-mobile: 36px;

$brand-width-desktop: 44px;
$brand-height-desktop: 44px;

//  transitions
// --------------------------------------------------------------------------

$transition-fast:                  240ms !default;
$transition-normal:                480ms !default;
$transition-slow:                  720ms !default;

// banner
$banner-text-align: center;
$banner-flex-align: center;

// arrows
$arrow-width: 40px;
$arrow-height: 40px;
$arrow-border-radius: $root-radius;

$arrow-bg-slider: color(greys, xdark);
$arrow-color-slider: color(greys, xlight);
$arrow-bg-slider-active: color(greys, xdark);
$arrow-color-slider-active: color(greys, xlight);

$arrow-bg-hero: color(greys, xdark);
$arrow-color-hero: color(greys, xlight);
$arrow-border-hero: none;
$arrow-distance-hero: 0;
$arrow-bg-hero-active: color(greys, xdark);
$arrow-color-hero-active: color(greys, xlight);


// colors
// primary
$black: color(root, black);
$white: color(root, white);

// greys
$color-grey-xdark: color(greys, xdark);
$color-grey-dark: color(greys, dark);
$color-grey-darken: color(greys, darken);
$color-grey-base: color(greys, base);
$color-grey-light: color(greys, light);
$color-grey-xlight: color(greys, xlight);
$color-grey-xxlight: color(greys, xxlight);
$color-grey-meta: color(greys, meta);

// fonts
$color-font-primary: color(greys, xlight);
$color-font-secondary: color(greys, base);
$color-font-menu: color(greys, xlight);
$color-font-menu-underline: color(greys, xlight);
$color-font-h1: color(greys, xlight);
$color-font-h2: color(site, orange);
$color-font-banner-h1: $color-font-h1;
$color-font-banner-h2: $color-font-h2;
$color-font-h3: color(greys, base);
$color-font-article: $color-font-primary;
$color-font-footer-title: color(greys, xlight);
$color-font-footer-links: color(greys, light);
$color-font-footer-copyright: color(greys, light);

$color-hamburger: color(greys, xlight);
$color-seperator: color(greys, dark);

$color-background-tertiary: color(site, background-tertiary);
$color-background-secondary: color(site, background-secondary);
$color-background-light: color(site, background-light);

// buttons
$button-primary: color(buttons, blue);
$button-primary-active: color(buttons, blue-active);
$button-primary-hover: color(buttons, blue-hover);
$button-primary-text: color(buttons, blue-text);

$button-secondary: color(buttons, blue);
$button-secondary-active: color(buttons, blue-active);
$button-secondary-hover: color(buttons, blue-hover);
$button-secondary-text: color(buttons, blue-text);

$button-hero: color(buttons, blue);
$button-hero-active: color(buttons, blue-active);
$button-hero-hover: color(buttons, blue-hover);
$button-hero-text: color(buttons, blue-text);
$button-hero-hover-text: color(buttons, blue-text-hover);

$button-schedule: color(buttons, grey);
$button-schedule-active: color(buttons, grey-active);
$button-schedule-hover: color(buttons, grey-hover);
$button-schedule-text: color(buttons, grey-text);
$button-schedule-hover-text: color(buttons, grey-text-hover);
// Slider
$color-dots-active: color(site, orange);
$color-dots-inactive: color(greys, xlight);

// Schedule 
$color-schedule-border: color(greys, darken);
$color-schedule-time: color(site, orange);
$color-schedule-show: color(greys, xlight);
$color-schedule-episode: color(greys, base);
$color-schedule-passed: color(greys, darken);
$color-schedule-passed-bg: color(site, background-secondary);
$color-schedule-active: color(site, orange);
$color-schedule-active-bg: color(greys, darken);
$color-schedule-active-font: color(greys, xligh);

$color-font-share: color(greys, light);
$color-card-primary: color(site, xlight);
$color-card-date: color(greys, base);
$color-card-sep: color(greys, base);

// Form
$color-error: color(site, error);
$color-font-label: color(greys, light);

$hero-overlay-width-smm: 135%;
$hero-overlay-width-md: 131%;
$hero-overlay-width-mlg: 128%;
$hero-overlay-width-lg: 126%;

$show-seperator: "/";
