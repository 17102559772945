// Layout: Grid breakpoints & parameters
// --------------------------------------------------------------------------

$grid-breakpoints: (
    //root: 0,
    xs:                            480px,
    sm:                            780px,
    smm:                           864px,
    md:                            1024px,
    mlg:                           1314px,
    lg:                            1380px
);

$grid-columns:                     12;
$grid-gutter-width:                20px;
$grid-container-width:             1264px;
$grid-container-width-slider:      1284px;
$grid-gutter-width-large:          60px;
$line-height-dropdown:             13px;

$header-height: 60px;
$header-height-desktop: 80px;

$root-vgrid: 12px;
$font-icon: "maxelle-iconfont";

$typo-list-bullet: '•'; // could be any variable from your iconfont if defined
