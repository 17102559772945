// ==========================================================================
// Functions
// ==========================================================================


@function stop-cache($ressource) {
    $cachebuster: random(9999);
    @return $ressource +"?v="+ $cachebuster;
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @return if($index,
 str-slice($string, 1, $index - 1) + $replace +
 str-replace(str-slice($string, $index +
 str-length($search)), $search, $replace),
 $string);
}

@function color($color-name, $color-value) {
    @return map-get(map-get($colors, $color-name), $color-value);
}

@function font($font-group, $font-reference) {
    @return map-get(map-get($fonts, $font-group), $font-reference);
}

/// Strips the unit from a number.
/// --------------------------------------------------
///
/// @param {Number (With Unit)} $value
///
/// @example scss - Usage
///   $dimension: strip-units(10em);
///
/// @example css - CSS Output
///   $dimension: 10;
///
/// @return {Number (Unitless)}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    @return $number;
}

/// Convert pixels to ems
/// --------------------------------------------------
///
/// eg. for a relational value of 12px write em(12) when the parent is 16px
/// if the parent is another value say 24px write em(12, 24)


@function em($pxval, $base: $root-font-size) {
    @if not unitless($pxval) {
        $pxval: strip-unit($pxval);
    }
    @if not unitless($base) {
        $base: strip-unit($base);
    }
    @return ($pxval / $base) * 1em;
}

/// Convert pixels to rems
/// --------------------------------------------------
///
// eg. for a relational value of 12px write rem(12)
// Assumes $root-font-size is the font-size of <html>
//

@function rem($pxval) {
    @if not unitless($pxval) {
        $pxval: strip-unit($pxval);
    }
    $base: $root-font-size;
    @if not unitless($base) {
        $base: strip-unit($base);
    }
    @return ($pxval / $base) * 1rem;
}

/// Do your Math!
/// --------------------------------------------------
///

@function sqrt($number) {
    $x0: 1;
    $x1: $x0;
    @for $i from 1 through 10 {
        $x1: $x0 - ($x0 * $x0 - abs($number)) / (2 * $x0);
        $x0: $x1;
    }
    @return $x1;
}

@function pow($number, $exponent) {
    $x0: 1;
    $number: $x0 * $number;
    @if $exponent > 0 {
        @for $i from 1 through $exponent {
            $x0: $x0 * $number;
        }
    }
    @return $x0;
}

/// Import once
/// --------------------------------------------------
///

$imported-once-files: ();

@function import-once($filename) {
    @if index($imported-once-files, $filename) {
        @return false;
    }

    $imported-once-files: append($imported-once-files, $filename);
    @return true;
}
