.show {
    .section {
        max-width: 850px;
    }

    .header_show {
        .iframe-container {
            overflow: hidden;
            padding-top: 56.25%;
            position: relative;
            iframe {
                border: 0;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    h2 {
        padding: 10px 0 0;
        font-size: 16px;
        text-transform: uppercase;
        color: $color-font-h2;
        font-family: $font-primary;
        font-weight: bold;
        span {
            position: relative;
            color: $color-font-primary;
            padding-left: 20px;
            color: $color-font-h3;
            font-weight: normal;
            &::before {
                position: absolute;
                content: $show-seperator;
                left: 5px;
                top: 0;
            }
        }
    }
    h3 {
        padding: 10px 0 0;
        font-size: 16px;
        text-transform: none;
        color: $color-font-h3;
    }
    p {
        font-size: 16px;
        line-height: 1.8;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        .btn + .btn {
            margin-top: 20px;
        }
        @include mq(xs) {
            .btn {
                padding: 10px 7px;
            }
            .btn + .btn {
                margin-top: 0;
                margin-left: 10px;
            }
            flex-direction: row;
        }
        @include mq(sm) {
            .btn {
                padding: 10px 20px;
            }
            .btn + .btn {
                margin-top: 0;
                margin-left: 20px;
            }
        }
    }
}

