.nav {
    display: none;

    @include mq(sm) {
        flex-direction: row;
        display: flex;
        justify-content: flex-start;
        margin-left: 40px;
        align-items: center;
    }
}

.nav__list {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    position: fixed;

    @include mq(sm) {
        height: 100%;
        flex-direction: row;
    }

    li {
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        &:hover,
        .is-active {
            &::after {
                position: absolute;
                left: 0;
                bottom: 0;
                content: "";
                display: block;
                width: calc(100% - 1px);
                height: 3px;
                background-color: $color-font-menu-underline;
            }
        }
    }
    > li + li {
        @include mq(sm) {
            margin-left: rem(30px);
        }
    }
}

.nav__link {
    position: relative;
    display: flex;
    align-items: center;
    color: $color-font-menu;
    padding: rem(($grid-gutter-width / 2)) 0;

    img {
        display: inline;
    }

    @include mq(md) {
        font-size: em(16px);
        line-height: 1;
        padding-top: rem($grid-gutter-width);
        padding-bottom: rem($grid-gutter-width);
    }
}

.navbar__button {
    background: transparent;
    display: flex;
    font-size: rem(20px);
    height: 54px;
    padding: 5px 0;
    @include mq(sm) {
        display: none;
    }
    position: relative;
    i {
        text-emphasis: none;
        color: $color-font-primary;
    }
    &.open {
        i {
            &::before {
                content: $me-icon-close;
            }
        }
    }
}

.nav__panel {
    position: absolute;
    top: #{$header-height};
    background-color: $color-background-secondary;
    color: $color-font-menu;
    height: calc(100vh - #{$header-height});
    width: calc(100vw);
    padding: 30px 25px;
    display: none;
    flex-direction: column;

    @include mq(sm) {
        display: none;
    }

    .nav__panel__list {
        list-style-type: none;
        height: inherit;
        width: 100%;
        //padding: 0 25px;
        li {
            font-size: em(16px);
            width: inherit;
            border-top: 1px solid #717174;
            .nav__link {
                justify-content: center;
                padding: rem(20px) 0;
            }
            &.nav__social {
                padding: rem(30px) 0;
                display: flex;
                justify-content: center;
                .social {
                    font-size: em(22px);
                    margin: 0 20px;
                    padding: 0;
                    color: #717174;
                }
            }
        }
    }
    .signup {
        text-align: center;
        .btn {
            max-width: 165px;
        }
    }
    &.open {
        display: flex;
        z-index: 15;
        @include mq(sm) {
            display: none;
        }
    }
}
