.banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;

    @include mq(smm) {
        display: grid;
        grid-template-columns: 40% 60%;
    }
    &--overlay {
        .banner__cover {
            img {
                width: 100%;
                display: block;
            }
            @include mq(smm) {
                flex-basis: 60%;
                max-height: 550px;
            }
        }

        .banner__inner {
            @include mq(smm) {
                position: relative;
            }
            width: 100%;
            height: 100%;
            background: $hero-background-mobile no-repeat;
            background-size: cover;
            text-align: $banner-text-align;
            max-width: $grid-container-width;
            padding-bottom: 40px;
            z-index: 10;
            @include mq(smm) {
                padding-bottom: 0;
                background: none;
                z-index: 10;
                &::before {
                    @include mq(smm) {
                        background: $hero-background-desktop no-repeat right;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: $hero-overlay-width-smm;
                        height: 100%;
                        content: "";
                        z-index: 0;
                    }
                    @include mq(md) {
                        width: $hero-overlay-width-md;
                    }
                    @include mq(mlg) {
                        width: $hero-overlay-width-mlg;
                    }
                    @include mq(lg) {
                        width: $hero-overlay-width-lg;
                    }
                }
            }

            .banner-card {
                min-height: 230px;
                padding: 30px;
                display: flex;
                align-items: $banner-flex-align;
                justify-content: center;
                flex-direction: column;
                &__header {
                    z-index: 10;
                }
                &__title.h1 {
                    line-height: .8;
                    a {
                        line-height: 1;
                        padding-top: 20px;
                        font-size: 28px;
                        color: $color-font-banner-h1;
                        @include mq(mlg) {
                            font-size: 42px;
                        }
                    }
                }
                &__title.h2 {
                    font-family: $font-primary;
                    color: $color-font-banner-h2;
                    text-transform: uppercase;
                    font-size: 18px;
                    padding: 10px 0 30px;
                    font-weight: bold;
                    @include mq(lg) {
                        font-size: 23px;
                    }
                }
                @include mq(smm) {
                    height: 100%;
                    max-width: 470px;
                    margin-left: auto;
                }
            }
        }
    }
}
