body {
    color: colors('textdark');
    font-size: $root-font-size;
    background-color: $color-background-light;
    font-family: $font-primary;
}

.container-slider {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $grid-container-width;
    padding-left: 25px;
    padding-right: 25px;
    @include mq(mlg) {
        padding-right: 10px;
        padding-left: 10px;
        max-width: $grid-container-width-slider;
    }
}

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $grid-container-width;
    padding-left: 25px;
    padding-right: 25px;
    color: $color-font-secondary;
    @include mq(mlg) {
        padding: 0;
    }
}

article p {
    color: $color-font-article;
}

a {
    text-decoration: none;
}

main {
    display: block;
    padding-bottom: 25px;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: $color-seperator;
}

h1,
.h1 {
    padding: 0;
    font-size: 22px;
    text-transform: uppercase;
    font-family: $font-h1;
    color: $color-font-h1;
    font-weight: bold;

    @include mq(smm) {
        padding: 0;
        font-size: em(30px);
    }
}

h2 {
    font-size: em(32px);
    padding: 45px 0 20px;
    font-family: $font-h1;
}

h3 {
    padding: 10px 0 15px;
    font-size: em(28px);
    text-transform: uppercase;
    font-family: $font-h1;
}

h4 {
    font-size: em(24px);
    padding: 45px 0 20px;
    font-family: $font-primary;
    color: $color-font-h2;
}

h5 {
    font-size: 20px;
    padding-bottom: 7px;
    text-transform: uppercase;
    font-family: $font-primary;
    color: $color-font-h1;
}

article {
    figure {
        img {
            max-width: 100%;
        }
    }
}
