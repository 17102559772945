.card-flex {
    .card-item {
        display: flex;
        flex-direction: column;

        @include mq(sm) {
            margin-bottom: 30px;
            flex-direction: row;
        }
        .card-thumb {
            @include mq(sm) {
                flex-basis: 40%;
                max-width: 40%;
                max-width: 408px;
            }
            img {
                width: 100%;
                border-radius: $root-radius;
            }
        }
        .card-body {
            position: relative;
            color: $color-font-primary;
            width: 100%;
            flex-basis: 60%;

            > div {
                position: relative;
                max-height: 320px;
                height: 100%;
                padding-bottom: 30px;
                @include mq(sm) {
                    max-height: 255px;
                    padding-bottom: 0;
                }

                @include mq(sm) {
                    &::after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        content: '';
                        width: 100%;
                        height: 2px;
                        background-color: $color-card-sep;
                    }
                }
            }
            @include mq(sm) {
                padding-left: 30px;
            }
            .date {
                color: $color-card-date;
                text-transform: uppercase;
                font-size: 13px;
                padding-top: 20px;
                @include mq(sm) {
                    font-size: 14px;
                    padding-top: 0;
                }
            }
            .title {
                color: $color-card-primary;
                text-transform: uppercase;
                line-height: 1.5;
                font-size: 21px;
                padding-top: 5px;
                font-weight: bold;
                @include mq(sm) {
                    padding-top: 10px;
                    font-size: 24px;
                }
            }
            .desc {
                line-height: 1.7;
                font-size: 16px;
                text-transform: none;
                padding-top: 10px;
                padding-bottom: 20px;
                @include mq(sm) {
                    padding-top: 20px;
                    padding-bottom: 30px;
                }
            }
        }
    }
}

.shows {
    .show-card {
        img {
            border-radius: $root-radius;
            width: 100%;
        }
        .title {
            margin-bottom: 30px;
            margin-top: 10px;
            color: $color-font-primary;
            font-size: 15px;
            font-weight: bold;
        }
    }
}
