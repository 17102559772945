// ==========================================================================
// Auto-generate SCSS file for icon font from SVG icons
// ==========================================================================

@function stop-cache($ressource) {
    $cachebuster: random(9999);
    @return $ressource +"?v="+ $cachebuster;
}

$font-icon: "maxelle-iconfont";
$font-path: "../fonts/" !default;

@font-face {
    font-family: $font-icon;
    src: url(stop-cache('#{$font-path}#{$font-icon}.woff')) format('woff'), url(stop-cache('#{$font-path}#{$font-icon}.ttf')) format('truetype');
}

[class*='me-icon-']::before {
    font-family: $font-icon;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
}

$me-icon-arrow-left: "\E001";
$me-icon-arrow-right: "\E002";
$me-icon-check: "\E003";
$me-icon-close: "\E004";
$me-icon-ellefictions-gros: "\E005";
$me-icon-ellefictions: "\E006";
$me-icon-envelope: "\E007";
$me-icon-error: "\E008";
$me-icon-facebook: "\E009";
$me-icon-max: "\E00A";
$me-icon-menu: "\E00B";
$me-icon-twitter: "\E00C";

.me-icon-arrow-left::before {
    content: $me-icon-arrow-left;
}

.me-icon-arrow-right::before {
    content: $me-icon-arrow-right;
}

.me-icon-check::before {
    content: $me-icon-check;
}

.me-icon-close::before {
    content: $me-icon-close;
}

.me-icon-ellefictions-gros::before {
    content: $me-icon-ellefictions-gros;
}

.me-icon-ellefictions::before {
    content: $me-icon-ellefictions;
}

.me-icon-envelope::before {
    content: $me-icon-envelope;
}

.me-icon-error::before {
    content: $me-icon-error;
}

.me-icon-facebook::before {
    content: $me-icon-facebook;
}

.me-icon-max::before {
    content: $me-icon-max;
}

.me-icon-menu::before {
    content: $me-icon-menu;
}

.me-icon-twitter::before {
    content: $me-icon-twitter;
}
