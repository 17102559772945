.socials {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 5px;
    @include mq(sm) {
        padding-top: 0;
        margin-left: -(20px / 4);
        margin-right: -(20px / 4);
    }
    li {
        margin: (20px / 4);
    }
}

.social {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    width: rem(17px);
    height: rem(17px);
    padding-right: rem(20px);

    &::after {
        display: inline-block;
        content: '';
        font-family: $font-icon;
    }
}

.social--facebook {
    &::after {
        content: $me-icon-facebook;
    }
}

.social--twitter {
    &::after {
        content: $me-icon-twitter;
    }
}

.social--email {
    &::after {
        content: $me-icon-envelope;
    }
}
