.btn {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    min-width: 110px;
    text-align: center;
    font-size: 14px;
    border-radius: $root-radius;
    letter-spacing: 1px;
    font-weight: bold;
    cursor: pointer;
    height: 36px;

    &--primary {
        background-color: $button-primary;
        color: $button-primary-text;
        &:hover {
            background-color: $button-primary-hover;
        }
        &:active {
            background-color: $button-primary-active;
        }
    }
    &--secondary {
        background-color: $button-secondary;
        color: $button-secondary-text;
        &:hover {
            background-color: $button-secondary-hover;
        }
        &:active {
            background-color: $button-secondary-active;
        }
    }

    &--schedule {
        background-color: $button-schedule;
        color: $button-schedule-text;
        &:hover {
            background-color: $button-schedule-hover;
            color: $button-schedule-hover-text;
        }
        &:active {
            background-color: $button-schedule-active;
            color: $button-schedule-hover-text;
        }
    }

    &--hero {
        background-color: $button-hero;
        color: $button-hero-text;
        &:hover {
            background-color: $button-hero-hover;
            color: $button-hero-hover-text;
        }
        &:active {
            background-color: $button-hero-active;
            color: $button-hero-hover-text;
        }
    }

    &[disabled] {
        opacity: .5;
        cursor: not-allowed;
    }
}
