.abonnement {
    margin-bottom: 50px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    @include mq(md) {
        flex-direction: row;
        align-items: center;
    }
    .abonnement__logo {
        display: block;
        margin-right: 10px;
        padding-right: 10px;
        i {
            display: block;
            color: $color-font-primary;
            font-size: 40px;
            line-height: 0;
            &.me-icon-ellefictions {
                font-size: 45px;
            }
        }
        padding-bottom: 15px;
        @include mq(md) {
            padding-bottom: 0;
        }
    }
    .abonnement__body {
        padding-bottom: 20px;
        @include mq(md) {
            padding-bottom: 0;
        }
        .abonnement__title {
            display: block;
            color: $color-schedule-show;
            line-height: 1.2;
            font-size: 16px;
        }
        .abonnement__info {
            color: $color-schedule-episode;
        }
    }

    .btn-group {
        margin-left: 0;
        @include mq(md, mlg) {
            justify-content: flex-end;
            width: 75%;
        }
        @include mq(mlg) {
            margin-left: auto;
            justify-content: flex-end;
        }
        .btn {
            color: $color-schedule-show;
            border: 1px solid $color-schedule-border;
            border-radius: $root-radius;
        }
        .btn + .btn {
            margin-top: $root-vgrid / 2;
        }
        @include mq(md) {
            position: relative;
            display: inline-flex;
            vertical-align: middle;
            overflow: auto;
            .btn {
                flex: 0 1 auto;
                min-width: inherit;
                &:first-child {
                    margin-left: 0;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                }
                &:not(:first-child):not(:last-child) {
                    border-radius: 0;
                }
                &:last-child {
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                }
            }
            .btn + .btn {
                margin-left: -1px;
                margin-top: 0;
            }
        }
    }
}
